import * as React from 'react';
import classNames from 'classnames';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonBackground from '../../MenuButtonBackground';
import MenuButtonRoot from '../../MenuButtonRoot';
import { MenuButtonProps } from '../../../MenuButton.types';
import skinsStyle from './styles/skins.scss';

const VerticalRibbonsMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  const {
    id,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(
        props.className,
        skinsStyle.VerticalRibbonsMenuButtonNSkin,
      )}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton && ariaHasPopup,
            isMoreButton,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <div className={skinsStyle.bgWrapper}>
            <div className={skinsStyle.ribbon} />
            <div className={classNames(skinsStyle.fold, skinsStyle.top)} />
            <div className={classNames(skinsStyle.fold, skinsStyle.bottom)} />
          </div>
          <MenuButtonBackground
            wrapperProps={{ dir, textAlign, id }}
            classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
          >
            {label}
          </MenuButtonBackground>
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default VerticalRibbonsMenuButtonNSkin;
