import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import MenuButton from '../../../../MenuButton/viewer/skinComps/VerticalRibbonsMenuButtonNSkin/VerticalRibbonsMenuButtonNSkin';
import styles from './VerticalRibbonsMenuButtonSkin.scss';

const VerticalRibbonsMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return <DropDownMenuBase {...props} styles={styles} Button={MenuButton} />;
};

export default VerticalRibbonsMenuButtonSkin;
